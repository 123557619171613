// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	$('.row-checkered-slider .flexslider.style-two-images .slides').each(function() {
		console.log($(this));
		$(this).slick({
			appendArrows: $(this).parent().find('.slick-control-wrapper'),
			appendDots: $(this).parent().find('.slick-control-wrapper'),
			dots: true,
			slidesToShow: 2,
			responsive: [{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: 1,
				}
			}]
		});
	});
	$('.row-checkered-slider .flexslider:not(.one-slide):not(.style-two-image)').flexslider({
		animation: "slide",
		prevText: "",
		nextText: "",
		controlNav: false,
		directionNav: true,
	});
}); /* end of as page load scripts */